import React from 'react'
import Helmet from 'react-helmet'
import { Layout, Heading, Row, Column, Image, Section, Text } from 'components'
import Blog from 'images/blogs/blog-14.png'
import 'components/css/stories.css'

const SITE_META = [
  {
    name: 'description',
    content: 'Mabilis, Madali, Mura',
  },
  {
    name: 'keywords',
    content:
      'digipay, fintech, e-payments, bills payment, ' +
      'mobile money, e-load, e-pins, microinsurance',
  },
  {
    property: 'og:url',
    content: `https://${process.env.GATSBY_REACT_APP_HOSTNAME}/stories/4DigipaySukiYouProbablyKnow`,
  },
  {
    property: 'og:type',
    content: 'website',
  },
  {
    property: 'og:title',
    content: '4 Digipay Suki You Probably Know',
  },
  {
    property: 'og:description',
    content: 'Kung suki ka ng Digipay, ikaw naman ngayon ang bida!',
  },
  {
    property: 'og:image',
    content: Blog,
  },
]

const ReasonsWhy = () => (
  <Layout>
    <Helmet title="4 Digipay Suki You Probably Know" meta={SITE_META}>
      <html lang="en" />
    </Helmet>

    <Row>
      <Column size="df:[100%] lg:[col-6]" py="sp4" px="sp3" m="auto">
        <Image width="100%" mb="sp3" src={Blog} />
        <Heading
          className="blog__title"
          mb="sp4"
          fontWeight="bold"
          textAlign="center"
        >
          4 DIGIPAY SUKI YOU PROBABLY KNOW
        </Heading>

        <Section mb="sp4">
          <Heading mb="sp2" fontSize="fsize-3" fontWeight="bold">
            JUDITH
          </Heading>
          <Text>
            Lahat tayo kilala si Judith dahil siya ang pinakasikat sa lahat.
            Lagi mo siyang nakikita kapag katapusan na ng buwan dahil bitbit
            niya ang kaliwa’t kanang BILLS. Kuryente, tubig, cable, internet,
            credit card, loans...name it! Pero kahit sandamakmak ang due date
            niya, kaya naman niyang bayaran lahat sa Digipay. ‘Yan ay dahil
            madiskarte si Judith!
          </Text>
        </Section>

        <Section mb="sp4">
          <Heading mb="sp2" fontSize="fsize-3" fontWeight="bold">
            LOADI
          </Heading>
          <Text>
            Siya ‘yung IDOL mo na hindi nawawalan ng LOAD. Mahilig kasi siyang
            tumawag, magtext, at mag-internet. Kaya bago pa ma-expire ang load,
            pumupunta na agad siya sa kapitbahay niyang Digipay Agent. Pwede
            siyang bumili ng load ng sa Globe, Smart, Sun, TM, Red, ABS-CBN,
            Cignal at PLDT. Lodi talaga siya, lalo na ang Digipay!
          </Text>
        </Section>

        <Section mb="sp4">
          <Heading mb="sp2" fontSize="fsize-3" fontWeight="bold">
            GAME-MAMIA
          </Heading>
          <Text>
            🔊 “Here I go again. My, my, how can I resist you?” 🔊 Ang sarap
            kapag tuluy-tuloy lang ang laban ‘di ba? Ganyan si ‘Game-Mamia’
            dahil nakapag-load siya ng gaming pins sa Digipay. Mahilig kasi
            siyang virtual items ng mga paborito niyang mobile game tulad ng
            Mobile Legends, Garena, Asiasoft, Blizzard, at marami pang iba. Game
            face on siya everyday with Digipay!
          </Text>
        </Section>

        <Section mb="sp4">
          <Heading mb="sp2" fontSize="fsize-3" fontWeight="bold">
            MONEY PACQUIAO
          </Heading>
          <Text>
            Madalas siyang sumusugod sa kakilala niyang Digipay Agent hindi para
            manuntok, kundi para magpadala through GCash, Smart Money or
            PayMaya. Kung kailangan ng allowance ng kanyang anak, hindi na niya
            kailangang lumayo at mamasahe pa for Mobile Money transaction. Kapag
            si Money Pacquiao ang naging suki mo, siguradong panalo ka sa extra
            income!
          </Text>
          <Text>
            May kakilala ka ba sa kanila... o baka naman ikaw ‘to? Hanggang sa
            muli, ka-Digipay!
          </Text>
        </Section>
      </Column>
    </Row>
  </Layout>
)

export default ReasonsWhy
